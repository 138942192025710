import qgans from 'images/resource_slider/qgans.jpg'
import adjoint from 'images/resource_slider/adjoint.png'

const demosArray: Resource[] = [
  {
    image: qgans,
    title: 'Quantum GANs',
    difficulty: 'easy',
    link: 'https://pennylane.ai/qml/demos/tutorial_quantum_gans/',
  },
  {
    image: adjoint,
    title: 'Adjoint Differentiation',
    difficulty: 'medium',
    link: 'https://pennylane.ai/qml/demos/tutorial_adjoint_diff/',
  },
]

export default demosArray
