import * as React from 'react'

import Layout from 'components/Layout'

import Hero from 'components/Hero'
import SideNavSection from 'components/sections/sideNav'
import SideNavigation from 'components/sections/sideNav/Navigation'
import Slider from 'components/slider'

import demosArrayLightning from 'data/demos/demosArrayLightning'
import Seo from '../../components/Seo'
import { sideNavData } from 'types'

const SideNavMenu = () => {
  const sideNavInfo: sideNavData = {
    title: 'Navigation',
    links: [
      { title: 'Lightning', id: '#intro' },
      { title: 'Key Features', id: '#key-features' },
      { title: 'Getting Started', id: '#getting-started' },
      { title: 'Demos', id: '#demos' },
      { title: 'Documentation', id: '#documentation' },
    ],
  }

  return <SideNavigation sideNavInfo={sideNavInfo} />
}

const SideNavMain = () => {
  return (
    <>
      <div id="intro" className="jumptarget">
        <h3>Lightning</h3>
        <p className="mt-8.5">
          Lightning is a high-performance quantum simulator, designed to provide
          the ultimate performance for quantum machine learning applications.
          Written in C++ and accessible via Python, there are two simulators in
          the Lightning family:{' '}
          <code style={{ padding: 0, marginBottom: '-5px' }}>
            lightning.qubit
          </code>{' '}
          and{' '}
          <code style={{ padding: 0, marginBottom: '-5px' }}>
            lightning.gpu
          </code>
          , ensuring you can simulate large workflows quickly, whether you are
          using GPUs locally or running on supercomputers. In addition,
          Lightning integrates seamlessly with{' '}
          <a href="../products/pennylane">PennyLane</a>, providing cutting-edge
          techniques for quantum optimization for the truly fastest quantum
          machine learning workflows.
        </p>
        <hr />
      </div>
      <div id="key-features" className="jumptarget">
        <h3>Key Features</h3>
        <ul className="checkbox">
          <li>
            <strong>Run anywhere.</strong>
            <br />
            With support for x86, ARM, AARCH64, and PowerPC, Lightning supports
            the widest variety of architectures, ensuring that you can use it
            with ease anywhere, from laptops to the cloud to supercomputing
            centers.
          </li>
          <li>
            <strong>GPU support powered by NVIDIA.</strong>
            <br />
            Take advantage of GPU clusters with{' '}
            <code style={{ padding: 0, marginBottom: '-5px' }}>
              lightning.gpu
            </code>{' '}
            powered by NVIDIA&apos;s{' '}
            <a
              href="https://developer.nvidia.com/cuquantum-sdk"
              target="_blank"
              rel="noopener noreferrer"
            >
              CuQuantum SDK
            </a>{' '}
            for GPU accelerated circuit simulation.
          </li>
          <li>
            <strong>Optimized for machine learning.</strong>
            <br />
            Lightning was built with machine learning in mind, so comes with
            built-in support for highly efficient computations of{' '}
            <a
              href="https://arxiv.org/abs/2009.02823"
              target="_blank"
              rel="noopener noreferrer"
            >
              quantum gradients
            </a>
            . Train and optimize your quantum algorithms with scale.
          </li>
          <li>
            <strong>Fast.</strong>
            <br />
            Built-in C++ kernels for a large variety of different quantum gates
            and operations ensures that quantum circuits are optimized for
            performance no matter your application, from quantum chemistry to
            machine learning.
          </li>
          <li>
            <strong>Seamless PennyLane integration.</strong>
            <br />
            Use Lightning in combination with PennyLane for direct access to
            PennyLane optimizers and algorithms. In addition, connect your large
            scale simulations with machine learning frameworks such as PyTorch,
            TensorFlow, and JAX.
          </li>
        </ul>
      </div>
      <div className="standard-section-top-margin">
        <div id="getting-started" className="jumptarget">
          <h3>Getting Started</h3>
          <p className="mt-5">
            <code style={{ padding: 0, marginBottom: '-5px' }}>
              lightning.qubit
            </code>{' '}
            is installed automatically alongside{' '}
            <a href="../products/pennylane">PennyLane</a>.
          </p>
        </div>
      </div>
      <div className="standard-section-top-margin">
        <div id="demos" className="jumptarget">
          <h3>Demos</h3>
          <p className="mt-5">
            Once you have Lightning up and running, explore our tutorials and
            demonstrations.
          </p>
          <div className="mt-10">
            <Slider demos={demosArrayLightning} />
          </div>
        </div>
      </div>
      {/* DOCS */}
      <div className="standard-section-top-margin">
        <div id="documentation" className="jumptarget">
          <h3>Documentation</h3>
          <p className="mt-10">
            Access the{' '}
            <a
              href="https://pennylane-lightning.readthedocs.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              full documentation
            </a>{' '}
            for more information.
          </p>
        </div>
      </div>
    </>
  )
}

const QuantumSimulatorsPage = () => {
  return (
    <Layout>
      <Hero
        subTitle="// Lightning"
        title="High-performance quantum simulators"
        image="lightning_hero.png"
        editorial={false}
      />
      <SideNavSection nav={<SideNavMenu />} main={<SideNavMain />} />
    </Layout>
  )
}

export default QuantumSimulatorsPage

export const Head = () => {
  return (
    <Seo
      title="Lightning"
      description="Class of high-performance state-vector simulators for CPUs and GPUs"
      image="lightning_hero.png"
    />
  )
}
